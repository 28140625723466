import React from "react"
import { Container, SimpleGrid, Box, Heading } from "@chakra-ui/react"
import CollectionCard from "./CollectionCard"
import { useCore } from "@app/hooks/useCore"
import type { Props } from "@app/pages/collections"
import type { PageProps } from "@root/types/global"

const CollectionList: React.FC<PageProps<Props>> = ({ page, collections }) => {
  const {
    helpers: { sanityContent },
  } = useCore()

  const item = {
    ...page,
    image: {
      alt: page?.image?.alt || "",
      src: page?.image?.asset?.url || "",
    },
    content: sanityContent(page?.content),
  }

  const items = collections?.edges?.map(({ node }) => node)

  return (
    <Container maxW="container.lg">
      <Box as="section" py={8} textAlign="center">
        <Heading as="h1" mb={4}>
          {item?.title}
        </Heading>

        <Box mb={8}>{item?.content}</Box>

        <SimpleGrid columns={[1, 3]} spacing={10}>
          {items && items?.map(collection => <CollectionCard key={collection.title} collection={collection} />)}
        </SimpleGrid>
      </Box>
    </Container>
  )
}

export default React.memo(CollectionList)
